export const localStorageBidKey = 'bid';

// Объект со значениями чекбоксов шага 5 Другие услуги
export const wantedServisesCheckboxes = {
  '#wanted_servises_form_input_check_box1': 'оформление лицензии',
  '#wanted_servises_form_input_check_box2': 'оклейка автомобиля',
  '#wanted_servises_form_input_check_box3': 'получение путевых листов',
  '#wanted_servises_form_input_check_box4': 'другое'
};

// Функция получения названия полей в зависимости от ключа формы
export function getFieldsNameDependsOnFormPlace(formPlace) {
  const payloadKey = formPlace === 'connect' ? 'bid_driver' : 'bid';
  const payloadFieldPhone = formPlace === 'connect' ? 'phone_number' : 'phone';

  return { payloadKey, payloadFieldPhone };
}

// Функция проверки инпута и получения его значения
export function inputValue (formPlace, inputType) {
  const inputId = `#${formPlace}_form_input_${inputType}`;
  const inputErrorId = `#${formPlace}_form_input_error_${inputType}`;

  const value = (inputType === 'check_box') ? $(inputId).is(':checked') : $(inputId).val();

  if (!value) {
    showInputError(inputErrorId, inputId);
    return '';
  }

  if ((inputType === 'phone') && !phoneRegex.test(value)) {
    $(inputErrorId).html('Введите корректный номер');
    showInputError(inputErrorId, inputId);
    return '';
  }

  $(inputErrorId).fadeOut(300);
  $(inputId).removeClass('error');

  return value;
}

// Функция показа ошибки из response
export function inputResponseError(formPlace, inputType, errors) {
  const inputErrorId = `#${formPlace}_form_input_error_${inputType}`;
  const inputId = `#${formPlace}_form_input_${inputType}`;

  const error = errors.join(',');

  $(inputErrorId).html(error);

  showInputError(inputErrorId, inputId);
}

// Функция возвращает ошибки из response
export function getErrorMsgFromResponse(response, field) {
  return response?.responseJSON?.[`${field}`]?.errors ?? 'Ошибка запроса';
}

// Функция показа ошибки при проверке значения инпута
export function showInputError (inputErrorId, inputId) {
  $(inputErrorId).fadeIn(300);
  $(inputId).addClass('error');
}

// Регулярное выражение для проверки правильности формата номера телефона
export const phoneRegex = /^\+\d{1,3}\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/;

// Очистка формы с фото
export function clearFormWithImages (formKey) {
  clearInputWithImage(formKey, 'front');
  clearInputWithImage(formKey, 'back');
}

// Обновление шага заявки для текущего заявки
export function updateStepInLocalStorage(step) {
  const bid = getBid();
  localStorage.setItem(localStorageBidKey, JSON.stringify({ ...bid, current_step: step }));
}

// Получение текущего заявки
export function getBid() {
  const bidRow = localStorage.getItem(localStorageBidKey) ?? undefined;
  const bid = bidRow ? JSON.parse(bidRow) : undefined;
  return bid;
}

// Валидация формы шага 5 Другие услуги
export function validationWantedServises() {
  const commentValue = $('#wanted_servises_form_input_textarea').val();
  const checkBoxWithValueOther = $('#wanted_servises_form_input_check_box4');

  hideErrorMsg( '#connect_form_input_error_wanted_servises'); // Очищакем предыдущие ошибки

  // Проверка на наличие выбранных пунктов
  const allCheckBoxUnselected = Object.keys(wantedServisesCheckboxes).every(input => $(input).is(':checked') === false);

  if (allCheckBoxUnselected) {
    showErrorMsg( '#connect_form_input_error_wanted_servises', 'Необходимо выбрать не менее одного варианта');
    return false;
  }

  // Проверка на наличие комментария при выбранном пункте Другое
  if (checkBoxWithValueOther.is(':checked') && !commentValue.length) {
    showErrorMsg( '#connect_form_input_error_wanted_servises', 'Необходимо ввести комментарий');
    return false;
  }

  return true;
}

// Показ ошибки (шаг 3,4,5)
export function showErrorMsg(element, msg) {
  $(element).html(msg).fadeIn(300);
}

// Скрытие ошибки (шаг 3,4,5)
export function hideErrorMsg(element) {
  $(element).fadeOut(100);
}

// Обработчик клика по полю загрузки изображения
export function triggerInputWithImageFile(clickedElementId, willTriggerElement) {
  const selectedFrontSide = clickedElementId.includes('front');

  $(`#form_input_${willTriggerElement}_${selectedFrontSide ? 'front' : 'back'}`).trigger('click');
}

// Обработчик выбора фото
export function handleSelectImageForForm(id, file, formKey) {
  const selectedFrontSide = id.includes('front');

  selectImage(selectedFrontSide ? 'front' : 'back', file, formKey);
}

// Обработчик отмены выбора фото
export function handleСlearInputWithImage(id, formKey) {
  const selectedFrontSide = id.includes('front');

  clearInputWithImage(formKey, selectedFrontSide ? 'front' : 'back');
}

// Функция выбора изображения
export function selectImage(side, file, formKey) {
  if (file) {
    const reader = new FileReader();
    reader.onload = function (event) {
      $(`#${formKey}_placeholder_${side}`).fadeOut(100);
      $(`#${formKey}_photo_preview_${side}`)
        .attr('src', event.target.result);
      $(`#${formKey}_container_upload_${side}`).addClass('d_flex').fadeIn(100);
    };
    reader.readAsDataURL(file);

    $('#connect_form_input_photo_error').fadeOut(200);
}}

// Функция отмены выбранного изображения
export function clearInputWithImage(formKey, side) {
  $(`#${formKey}_photo_preview_${side}`).attr('src', '').val('');
  $(`#${formKey}_container_upload_${side}`).fadeOut(100);
  $(`#${formKey}_placeholder_${side}`).fadeIn(100);
  $(`#form_input_${formKey}_${side}`).val(null);
}
