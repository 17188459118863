import { getBid,
  getErrorMsgFromResponse,
  getFieldsNameDependsOnFormPlace,
  inputResponseError,
  inputValue,
  localStorageBidKey,
  showErrorMsg,
  updateStepInLocalStorage,
  validationWantedServises,
  wantedServisesCheckboxes } from './bids';

// Базовый url
const baseUrl = '/api/v1';

// Path для работы с шагами заявки
const bidsStepperPath = '/bid_drivers';

// Функция изменения положения хедера страницы с изображением
// в соответствии с высотой шапки
export function updatePageHeaderOffsetTop() {
  const headerHeight = $('.header').outerHeight(true);
  const scrollableMenu = $('#scrollable_menu').outerHeight(true);

  $('.menu').css('top', headerHeight - scrollableMenu);
}

// Функция показа фона попапа
export function showPopupBackground () {
  $('body').css('overflow', 'hidden');

  $('.popup_background').addClass('is_open');
}

// Функция сокрытия фона попапа
export function hidePopupBackground () {
  $('body').css('overflow', 'auto');

  $('.popup_background').removeClass('is_open');
}

// Функция закрытия сайдбара
export function closeSidebar () {
  hidePopupBackground();

  $("[id^='sidebar']").removeClass('is_open');
}

// Функция показа попапа после успешной отправки заявки
export function showSuccessPopup() {
  if (window.location.pathname === '/') {
    showPopupBackground();

    $('.popup_success').fadeIn(300);
    $('.bid').fadeOut(300);
  
    localStorage.clear();
    
    return;
  }

  $("div[id^='bid_step']").fadeOut(300);
  $('.popup_success').addClass('popup_success_connect');
  $('.popup_success').delay(300).fadeIn(300);

  localStorage.clear();
}

// Функция закрытия попапа с успешной отправкой заявки
export function closePopupSuccess () {
  const footerPopupOpen = $('.popup_success_footer').is(':visible');

  if (footerPopupOpen && window.location.pathname === '/connect') {
    $('.popup_success_footer').fadeOut(300, function () {
      $('.popup_background').removeClass('is_open');
    });

    return;
  }
  $('.popup_success').fadeOut(300, function () {
    hidePopupBackground();
  });
}

// Функция проверки введенных значений и отправки запроса с заявкой
export function formSubmit (formPlace) {
  const phoneInputValue = inputValue(formPlace, 'phone');
  const nameInputValue = inputValue(formPlace, 'name');
  const checkBoxInput = inputValue(formPlace, 'check_box');
  const commentValue = $(`#${formPlace}_form_input_textarea`).val();

  if ( phoneInputValue && checkBoxInput ) {
    const { payloadFieldPhone, payloadKey } = getFieldsNameDependsOnFormPlace(formPlace);

    const formData = { [payloadFieldPhone]: phoneInputValue.replace(/[()\s/-]/g, '') };

    if (commentValue) formData.comment = commentValue;
    if (nameInputValue) formData.name = nameInputValue;

    showSpinner();

    $.ajax({
      url: getUrlByStep('step_one', formPlace),
      type: 'POST',
      dataType: 'json',
      data: { [payloadKey]: formData }
    })
    .done(function (e) {
      if (formPlace === 'footer') {
        successSendFooterForm(formPlace);

        return;
      }

      const { id } = e?.bid_driver; // ID заявки

      localStorage.clear();
      localStorage.setItem(localStorageBidKey, JSON.stringify({ id, current_step: 2 }));

      $(`#${formPlace}_form_input_phone`).val('');
      $(`#${formPlace}_form_input_check_box`).prop('checked', false);

      goToStep(2);
    })
    .fail(function (response) {
      const errors = getErrorMsgFromResponse(response, payloadKey);

      inputResponseError(formPlace, 'phone', errors[payloadFieldPhone]);
    }).always(hideSpinner());
  }
}

// Функция переключения шагов заявки
export function goToStep(step) {
  $("div[id^='bid_step']").fadeOut(300);
  $(`div[id^=bid_step${step}]`).delay(299).fadeIn(300); // Задержка нужна для более планого перехода
  updateStepInLocalStorage(step);
}

// Функция пропуска всех шагов заявки
export function skipFormSteps() {
  showSpinner();

  const bid = getBid();

  $.ajax({
    url: getUrlByStep('step_two', bid.id),
    type: 'GET'
  })
  .done(showSuccessPopup)
  .fail(function (response) {
    const inputErrorId = '#connect_form_step2_error';

    $(inputErrorId)
      .html(`Ошибка: ${response.statusText}, код: ${response.status}.\n Попробуйте повторить позже`)
      .fadeIn(300);
  }).always(hideSpinner());
}

// Функция проверки введенных значений и отправки запроса с фото ВУ (шаг 3,4)
export function submitFormWithImage(formKey) {
  const frontSideImage = $(`#form_input_${formKey}_front`)[0].files;
  const backSideImage = $(`#form_input_${formKey}_back`)[0].files;

  if (!frontSideImage.length || !backSideImage.length) {
    showErrorMsg(`#connect_form_input_photo_error_${formKey}`, 'Загрузка фото обязательна');
    return;
  }
  showSpinner();
  const bid = getBid();

  const formData =  new FormData();
  formData.append(`bid_driver[${formKey}_front]`, frontSideImage[0]);
  formData.append(`bid_driver[${formKey}_back]`, backSideImage[0]);
  formData.append('bid_driver[bid_type]', bid.bidType);

  const isStep3 = formKey === 'driver_license';

  $.ajax({
    url: getUrlByStep(isStep3 ? 'step_three' : 'step_four', bid.id),
    type: 'PATCH',
    processData: false,
    contentType: false,
    data: formData
  }).done(function () {
    if (bid.current_step === 3) {
      goToStep(4);
      return;
    }

    $.ajax({
      url: getUrlByStep('finish_bid', bid.id),
      type: 'GET'
    }).done(showSuccessPopup).always(hideSpinner());
  }).fail(function (responseWithError) {
    showErrorMsg(
      '#connect_form_input_photo_error',
      `Ошибка: ${responseWithError.statusText}, код: ${responseWithError.status}.\n Попробуйте повторить позже`
    );
  }).always(hideSpinner());
}

// Функция проверки введенных значений и отправки запроса с фото ВУ (шаг 5)
export function submitFormWithWantedServises() {
  $('.spinner').css('display', 'flex').fadeIn(100);

  const isValid = validationWantedServises();

  if (!isValid) return;

  const fields = getSelectedFields();

  const bid = getBid();

  const formData = { wanted_services: fields, bid_type: bid.bidType };

  $.ajax({
    url: getUrlByStep('step_five', bid.id),
    type: 'PATCH',
    dataType: 'json',
    data: { bid_driver: formData }
  })
  .done(function () {
    $.ajax({
      url: getUrlByStep('finish_bid', bid.id),
      type: 'GET'
    }).done(showSuccessPopup).always(hideSpinner());
  })
  .fail(function (responseWithError) {
    showErrorMsg(
      '#connect_form_input_error_wanted_servises',
      `Ошибка: ${responseWithError.statusText}, код: ${responseWithError.status}.\n Попробуйте повторить позже`
    );
  });
}

// Функция для получения выбранных пользователем значений
function getSelectedFields() {
  const commentValue = $('#wanted_servises_form_input_textarea').val();
  const checkBoxWithValueOther = '#wanted_servises_form_input_check_box4';
  const checkBoxSelected = Object.keys(wantedServisesCheckboxes).filter(input => $(input).is(':checked'));

  return checkBoxSelected.reduce((acc, item) => {
    // Получаем значение поля Другое в формате "другое: комментарий"
    if (item === checkBoxWithValueOther && commentValue.length) {
      acc.push(`${wantedServisesCheckboxes[item]}: ${commentValue}`);
      return acc;
    }
    acc.push(wantedServisesCheckboxes[item]);
    return acc;
  }, []);
}

/* Функция возвращающая корректный url в зависимости от шага заявки
   Параметр функции step принимает значения шагов заявки в формате: step_ + номер шага латиницей
   Параметр функции bidId в первом шаге принимает значение места вызова формы, в остальных случаях id заявки
*/
function getUrlByStep(step, bidId) {
  const path = `${bidsStepperPath}/${bidId}`;
  // Объект с Path по шагам заявки
  const urlsByStep = {
    step_one: bidId === 'connect' ?  `${bidsStepperPath}/step_phone_number` : '/bids',
    step_two: `${path}/skip_bid_driver`,
    step_three: `${path}/step_driver_license`,
    step_four: `${path}/step_vehicle_certificate`,
    step_five: `${path}/step_wanted_services`,
    finish_bid:`${path}/finish_bid_driver`
  };

  return baseUrl + urlsByStep[step];
}

// Функция для обработки успешного результата отправки заявки из футера
function successSendFooterForm(formPlace) {
  clearFooterForm(formPlace);

  if ( window.location.pathname === '/connect') {
    $('.popup_background').addClass('is_open');
    $('.popup_success_footer').fadeIn(300);

    return;
  }
  showPopupBackground();

  $('.popup_success').fadeIn(300);
  $('.bid').fadeOut(300);
}

// Функция для очистки формы в футере
function clearFooterForm(formPlace) {
  $(`#${formPlace}_form_input_name`).val('');
  $(`#${formPlace}_form_input_phone`).val('');
  $(`#${formPlace}_form_input_textarea`).val('');
  $(`#${formPlace}_form_input_check_box`).prop('checked', false);
}

// Функция установки типа заявки
export function setBidType(event) {
  const { dataset: { bid_type: bidType } } = event.currentTarget;

  const bid = getBid();
  localStorage.setItem(localStorageBidKey, JSON.stringify({ ...bid, bidType }));
}

// Функции показа и скрытия спиннера
const showSpinner = () => $('.spinner').css('display', 'flex').fadeIn(100);
const hideSpinner = () => $('.spinner').fadeOut(100);
