import Inputmask from './vendor/inputmask/inputmask.es6';

import { closePopupSuccess,
  closeSidebar,
  formSubmit,
  goToStep,
  setBidType,
  showPopupBackground,
  skipFormSteps,
  submitFormWithImage,
  submitFormWithWantedServises,
  updatePageHeaderOffsetTop } from './functions/main';

import { getBid,
  handleSelectImageForForm,
  handleСlearInputWithImage,
  triggerInputWithImageFile } from './functions/bids';

$(function () {
  // Добавление анимации к сайдбару после прогрузки страницы
  // для предотвращения срабатывания анимации при её первой прогрузке
  $("[id^='sidebar']").css('transition', 'margin-left .5s');

  // Маска для ввода телефона
  const inputMask = new Inputmask('+7 (999) 999-99-99');
  inputMask.mask('.form_input_phone');

  // Инициализация положения хедера страницы при загрузке страницы
  updatePageHeaderOffsetTop();

  // Обновление положения хедера страницы при изменении размеров окна
  $(window).on('resize', function() {
    updatePageHeaderOffsetTop();
  });

  // Открытие сайдбара по клику на бургерменю и смена иконки
  $('#open_sidebar').on('click', function () {
    showPopupBackground();

    $('#sidebar').addClass('is_open');
  });

  // Закрытие сайдбара по клику на крестик и смена иконки
  $("[id^='close_sidebar']").on('click', closeSidebar);

  $('.popup_background').on('click', function () {
    if ($("[id^='sidebar']").hasClass('is_open')) closeSidebar();
    else closePopupSuccess();
  });

  // Закрытие попапа об успешной отправке заявки по клику на крестик
  $('.close_popup_success').on('click', closePopupSuccess);

  // Отправка запроса по клику на кнопку "Отправить заявку" на странице Подключиться и Главная
  $('[id="connect_submit"]').on('click', function (e) {
    e.preventDefault();

    formSubmit('connect');
  });

  // Отправка запроса по клику на кнопку "Отправить заявку" на странице Подключиться
  $('#footer_submit').on('click', function (e) {
    e.preventDefault();

    formSubmit('footer');
  });

  // Раскрытие и сворачивание ответа на вопрос на странице Вопрос-ответ
  $('.questions_item').on('click', function () {
    const id = $(this).attr('id');

    $(`#expand_more_${id}, #expand_less_${id}`).toggleClass('d_none d_flex');
    $(`#answer_${id}`).toggleClass('expanded');
  });

  // Раскрытие и сворачивание элементов по нажатию на кнопку "Показать еще"
  $('.expand_button').on('click', function () {
    const buttonId = $(this).attr('id');

    // Получаем тип элементов, которые надо раскрыть или скрыть
    const itemsType = buttonId.split('_')[1];

    $(this).find("[id^='expand']").toggleClass('d_none');

    $(`#expandable_${itemsType}`).toggleClass('expanded');
  });

  // Открытие сайдбара со списком городов при клике по городу
  $('.selected_city').on('click', function () {
    showPopupBackground();

    $('#sidebar_cities').addClass('is_open');
  });

  // Показ первого шага заявки
  (() => {
    const bid = getBid();

    $("div[id^='bid_step']").hide();
    $(`div[id^="bid_step${bid?.current_step ?? 1}"]`).fadeIn(300);
  })();

  // Пропуск всех шагов заявки по клику на кнопку Пропустить заполнение анкеты
  $('#bid_skip_steps').on('click', skipFormSteps);

  // Переход на шаг 3
  $('button[id^="next_step3"]').on('click', function (e) {
    setBidType(e);
    
    goToStep(3);
  });

  // Обработка события клика по полю загрузки изображения
  $("[id^='form_field_container_']").on('click', function (e) {
    const { id } = e.currentTarget;

    const clickedByDriverLicenseContainer = id.includes('driver_license');

    triggerInputWithImageFile(id, clickedByDriverLicenseContainer ? 'driver_license' : 'vehicle_certificate');
  });

  // Обработчик события выбора фото
  $("[id^='form_input_']").on('change', function (e) {
    const file = this.files[0];

    const { id } = e.currentTarget;

    const clickedByDriverLicenseContainer = id.includes('driver_license');

    handleSelectImageForForm(id, file, clickedByDriverLicenseContainer ? 'driver_license' : 'vehicle_certificate');
  });

  // Обработчик события отмены выбранного фото
  $("[id^='cancel_upload_icon_']").on('click', function (e) {
    e.stopPropagation();

    const { id, offsetParent } = e.currentTarget;

    const clearDriverLicenseContainer = offsetParent.id.includes('driver_license');

    handleСlearInputWithImage(id, clearDriverLicenseContainer ? 'driver_license' : 'vehicle_certificate' );
  });

  // Отправка запроса по клику на кнопку "Следующий шаг" на странице Подключиться (шаг 3)
  $("[id^='connect_form_submit_']").on('click', function (e) {
    const { id } = e.currentTarget;

    const isDriverLicenseForm = id.includes('driver_license');
    submitFormWithImage(isDriverLicenseForm ? 'driver_license' : 'vehicle_certificate');
  });

  // Обработка нажатия на стрелку назад ( шаги 3,4,5 )
  $("[id^='stepBack']").on('click', function (e) {
    setBidType(e);
    
    const { id } = e.currentTarget;
    const step = id.match(/\d+/)[0];
    goToStep(step);
  });

  // Обработка клика по чекбоксу "другое" для отключения disabled (на странице Подключиться также для показа textarea)
  $('#wanted_servises_form_input_check_box4').on('click', function () {
    const checked = $('#wanted_servises_form_input_check_box4').is(':checked');

    const isTextareaHidden = $('#wanted_servises_form_input_check_box4').hasClass('hidden_textarea');

    if (isTextareaHidden) {
      $('#wanted_servises_form_input_textarea').css('visibility', checked ? 'visible' : 'hidden');
    }

    $('#wanted_servises_form_input_textarea').prop('disabled', !checked);
  });

  // Отправка запроса по клику на кнопку "Отправить Заявку" пункта "Другие услуги таксопарка"
  $('#connect_submit_wanted_servises').on('click', submitFormWithWantedServises);

  // Переход на шаг "Другие услуги таксопарка"
  $('#wanted_services').on('click', function (e) {
    setBidType(e);

    goToStep(5);
  });
});
